import http from '@/http-common'

const URL = '/api/admin/dossier'

class DossierService {
  index(page, params) {
    const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    return http.get(`${URL}?page=` + page + `&` + queryParams)
  }
  show(dossierId) {
    return http.get(`${URL}/${dossierId}`)
  }
  update(dossierId, data) {
    return http.put(`${URL}/${dossierId}`, {
      "dossier": data
    })
  }
  delete(dossierId) {
    return http.delete(`${URL}/${dossierId}`)
  }
  approve(dossierId) {
    return http.post(`${URL}/${dossierId}/approve`)
  }
  uploadFiles(dossierId, files, type) {
    return http.post(`${URL}/${dossierId}/documents/upload/${type}`, files)
  }
  downloadFile(dossierId, fileId) {
    return http.get(`${URL}/${dossierId}/file/${fileId}`, {
      responseType: 'blob'
    })
  }
  createExternalDataToken(dossierId, copyDossier = false) {

    return http.get(`${URL}/${dossierId}/generate-data-token?copydossier=${copyDossier}`)
  }
  overwriteQuotePayments(quotes, type) {
    return http.post(`${URL}/quote/overwrite-payments`, {
      "quotes": quotes,
      "lijfrente_type": type
    })
  }
  uploadQuoteFile(data, id) {
    return http.post(`${URL}/quote/${id}/upload-file`, data)
  }
  downloadQuoteFile(id) {
    return http.get(`${URL}/quote/${id}/download-file`, {
      responseType: 'blob'
    })
  }
  deleteQuoteFile(id) {
    return http.delete(`${URL}/quote/${id}/delete-file`)
  }
  updateFileVisibility(file) {
    return http.post(`${URL}/file/update-visibility`, file)
  }
  getDossierSteps() {
    return http.get(`${URL}/steps`)
  }
  manuallyChangeStep(dossierId, stepId) {
    return http.post(`${URL}/${dossierId}/set-steps`, {
      'step_id': stepId
    })
  }
  generateQuotation(dossierId) {
    return http.get(`${URL}/${dossierId}/download-quotation`, {
      responseType: 'blob'
    })
  }
  generateInvoice(dossierId) {
    return http.get(`${URL}/${dossierId}/download-invoice`, {
      responseType: 'blob'
    })
  }
  generateSigned(dossierId, type, fundId = '') {
    return http.get(`${URL}/${dossierId}/download-signed/${type}?fundId=${fundId}`, {
      responseType: 'blob'
    })
  }
  syncFilesEfficy(id, data){
    return http.post(`${URL}/${id}/export-to-efficy`, data)
  }

  //Afhandeling aanvraag
  setRejection(popup, id){
    return http.post(`${URL}/${id}/handle`, {reason: popup.input, type: 'dossier', subType: 'handling_quote_denied'})
  }
  setFundRejection(popup, id, fundId){
    return http.post(`${URL}/${id}/handle`, {reason: popup.input, type: 'fund', pension_fund_history_id: fundId, subType: 'handling_benefit_request_denied'})
  }
  setFundNotYet(popup, id, fundId){
    return http.post(`${URL}/${id}/handle`, {reason: popup.input, type: 'fund', pension_fund_history_id: fundId, subType: 'handling_fund_received_denied'})
  }
  setCompletionStep(popup, id){
    if(popup.step == 0)
      return http.post(`${URL}/${id}/handle`, {type: 'dossier', subType: 'handling_quote_sent'})
    if(popup.step == 1)
      return http.post(`${URL}/${id}/handle`, {type: 'dossier', subType: 'handling_quote_accepted'})
    if(popup.step == 2)
      return http.post(`${URL}/${id}/handle`, {handling_funds_received_type: popup.input, type: 'dossier', subType: 'handling_funds_received'})
    if(popup.step == 3){
      let formData = new FormData();
      formData.append('polis_file', popup.polis_upload)
      formData.append('handling_fund_transferred_type', popup.input + '')
      formData.append('type', 'dossier')
      formData.append('subType', 'handling_fund_transferred')
      return http.post(`${URL}/${id}/handle`, formData)
    }
  }
  setFundCompletionStep(popup, id, fundId){
    if(popup.step == 10)
      return http.post(`${URL}/${id}/handle`, {reason: '', type: 'fund', pension_fund_history_id: fundId, subType: 'handling_benefit_request_sent'})
    if(popup.step == 11)
      return http.post(`${URL}/${id}/handle`, {reason: '', type: 'fund', pension_fund_history_id: fundId, subType: 'handling_benefit_request_accepted'})
    if(popup.step == 12)
      return http.post(`${URL}/${id}/handle`, {handling_value_transfer_expire_date: popup.input, type: 'fund', pension_fund_history_id: fundId, subType: 'handling_fund_transferred'})
    if(popup.step == 13)
      return http.post(`${URL}/${id}/handle`, {handling_fund_transferred_amount: popup.input, handling_fund_date: popup.input2, type: 'fund', pension_fund_history_id: fundId, subType: 'handling_fund_received_accepted'})
  }
  resetToStep(step, id, fundId){
    if(fundId){
      return http.post(`${URL}/${id}/handle`, {step: step, type: 'fund', pension_fund_history_id: fundId, subType: 'reset'})
    }
    return http.post(`${URL}/${id}/handle`, {step: step, type: 'dossier', subType: 'reset'})
  }
}

export default new DossierService()